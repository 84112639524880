<template>

  <!-- Start Page Title Area -->
  <div class="page-title-area">
    <div class="container">
      <div class="page-title-content">
        <h2>Frequently Asked Questions</h2>
        <ul>
          <li>
            <router-link to="/" class="nav-link">
              <i class="bx bx-home"></i>
              Home
            </router-link>
          </li>
          <li class="active">FAQ</li>
        </ul>
      </div>
    </div>
  </div>
  <!-- End Page Title Area -->


  <!-- Start FAQ Area -->
  <section class="standard-text-page-area">
    <div class="container">
      <div class="faq-area">
<!--        <div class="col-lg-6">-->
<!--          <div class="faq-img"/>-->
<!--        </div>-->



        <div class="">
          <div class="faq-accordion">


            <div v-for="questionSet in questionSets" v-bind:key="questionSet.Q">
              <!-- Subsection start -->
              <h3>{{questionSet.title}}</h3>

              <ul class="accordion">

                <li class="accordion-item" v-for="qa in questionSet.qas" v-bind:key="qa.Q">
                  <a class="accordion-title" href="javascript:void(0)">
                    <i class="bx bx-plus"></i>
                    {{qa.Q}}
                  </a>

                  <div class="accordion-content">
                    <p>{{qa.A}}</p>
                  </div>
                </li>

              </ul>
              <!-- Subsection end -->
            </div>


          </div>
        </div>
      </div>
    </div>


  </section>
  <!-- End FAQ Area -->

</template>

<script>
import $ from 'jquery'

export default {


  data() {
    return {
      questionSets: [

        {
          "title": "Training content",
          "qas": [
            {
              "Q": "Are the fundamental questions also part of the advanced training?",
              "A": "Not directly, they are not copied. But all topics of the fundamental questions are covered also in the advanced training. Please note that the level from easy to hard are different in advanced level. Means e.g. the level advanced in the fundamental training is similar to level easy in the advanced training. In other words; the advanced training is harder than the fundamental training and you will get different questions." ,
            },
            {
              "Q": "Can I use the training to prepare for a certificate exam?",
              "A": "That depends on the training. The free training is just a start and is not exam preparation. Take a look at the advanced training for CompTIA Linux+ or LPIC-1. All required topics and commands should be listed. That said, if you come across a question, use additional material to dive deeper into the topic as no one knows what exactly will be asked in the real exam.",
            },
            {
              "Q": "Do you cover all questions of a certificate exam?",
              "A": "Of course not. Exam questions are top secret and nobody knows the questions. But we cover the topics, so you get a feeling what kind of questions are coming with the exam. Reading the explanation and searching in the internet for possible parameters of a command, is helpful to be prepared. You may also want to learn by reading websites and maybe watching external training videos to understand the topics. Some questions provide links to external sites in the explanation.",
            },
            {
              "Q": "Can I use it on a smartphone?",
              "A": "Yes, the page supports mobile usage and adapts the narrowed display. There is no app, just use the website.",
            },
            {
              "Q": "Is there a timer?",
              "A": "No, usually to learn by also reading the explanations does not work with a timer. But the world is full of clocks you can use if you need some pressure. This is by design and based on real experience of learning for the exam.",
            },
            {
              "Q": "Can I interrupt a training session?",
              "A": "Any time! It saves every selection automatically. You can close the browser and come back whenever you want to finish it. We reserve the right to delete answers and statistics after 24 months of inactivity in answering questions.",
            },
            {
              "Q": "Creating a session - what shall I select?",
              "A": "Use your statistics to see where you have gaps and concentrate on that topics and level or leave it as it is to touch all topics.",
            },
            {
              "Q": "Creating a session - what does the system choose?",
              "A": "It takes questions from the selected areas and level. But it also prefers questions you answered wrong so far.",
            },
            {
              "Q": "Why do questions appear again even if I did not select the specific section?",
              "A": "The system remembers which questions you answered wrong and wants you to solve them to force you in getting a complete knowledge of required topics.",
            },
            {
              "Q": "What happens if I press the verify button?",
              "A": "Then the question is locked with your answer and can not be changed anymore in the current session. In that moment it also displays an explanation if available. In the navigation the question is marked with the color green or red to display the result.",
            },
            {
              "Q": "Which skill levels do exist?",
              "A": "From 1 for easy to 4 for hard. Please note that this range is only valid within one training product. Do not compare for example the hard level of the free training with the advanced training. A hard level in free training is maybe an easy or normal level in advanced training.",
            },
            {
              "Q": "What selectable skill level do I need for the exam?",
              "A": "In advanced training, some questions are very difficult and are marked as level 4 'hard'. Level 3 'advanced' is usually sufficient for the exam to reach ca 80% of max score - if you would answer all level 3 correctly. Level 4 'hard' is for those who want to play it safe and have to delve even deeper to answer them, which increases their chances of passing the exam. So dont be frustrated to much on level 4 questions as some of them are really hard. It is more important to reach a top score of level 3 questions plus some, but not all of level 4 questions.",
            },
            {
              "Q": "I found a wrong question, what can I do?",
              "A": "First of all, we ask for forgiveness. This can happen, because we are all only human. You can help us improve the questions and contact us by email with the text of the question and your opinion. Then we will correct it. However, we will collect the improvements and implement them at a later date.",
            },
            {
              "Q": "What is a good way to learn?",
              "A": "It is recommended to use different sources. Bits & Bytes is just one but expects you to dive into the topics also by reading e.g. in internet or books. Video tutorials are also recommended. For example if you find a question around a command, do not only do a research regarding the displayed parameters, but also take a look in other possible parameters and mainly understand what the command can be used for.",
            },
            {
              "Q": "How difficult is the actual exam and how much time should I allow for studying?",
              "A": "The real exam is quite difficult. As a Linux beginner, you should expect to study for about 3-6 months (a few hours a day). For people with Linux experience, about 3-6 weeks of intensive study will suffice. But don't let this discourage you, with the right learning tools, many have already made it and you certainly will too!",
            },
            {
              "Q": "Does it fit to CompTIA+ XK0-005?",
              "A": "It was updated to cover the CompTIA+ XK0-005 exam objectives. LPIC-1 is similar.",
            }

          ]
        },

        {
          "title": "Training statistics",
          "qas": [
            {
              "Q": "When do I know I am ready for the exam?",
              "A": "As we dont know the real exam questions, the goal is to understand the topics in depth. Monitor your statistics to ensure reaching a good and constant level up to 'advanced'. The level 'hard' is not necessarily required to be completely answered correct, but you can see it as approach of hardening the depth (and of course even in exam, there are some hard questions). Do also not underestimate the easy questions, as those will be also touched in the real exam. Check also the doughnut graph to ensure not missing questions.",
            },
            {
              "Q": "What is a recommended strategy to start?",
              "A": "Maybe first start with random questions to find your gaps. Then focus on different single sections to dive concentrated into a topic. At the very end try a random selection again.",
            },
            {
              "Q": "Why do I not see any or just a few statistics?",
              "A": "There is not enough data collected yet to display meaningful charts. Please answer more questions.",
            },
            {
              "Q": "Can I reset my statistics?",
              "A": "No, it is by design to keep it personal and account sharing makes no sense with that.",
            },
            {
              "Q": "What does the progress chart display?",
              "A": "The lines are calculated by the last several questions answered and displays how your level went from zero to 100 percent. If you answer questions wrong, the line goes down until you answered more correctly for a while.",
            },
            {
              "Q": "What does the coverage doughnut say?",
              "A": "It reflects all available questions of the topic. Displaying if there are still some answered wrong or not answered so far. But concentrate more on the skill gain.",
            },
            {
              "Q": "What does the correct / answered numbers say?",
              "A": "It is just a counter for yourself. Even if you answer a question twice, it counts up.",
            },
            {
              "Q": "What does the complexity graph say?",
              "A": "Every question has a level: easy, normal, hard. It monitors if you are good or have gaps in a specific level. So you know what kind of questions you should concentrate on.",
            },
            {
              "Q": "So many questions, do I need to answer all of them?",
              "A": "First of all, you can not select questions yourself in a session. The systems knows which questions you have trouble with. But overall the answer is NO. Every required command is covered by multiple questions and often just from a different perspective. But it also means that it is wise to not only know for example one command line parameter of a command.",
            }
          ]
        },

        {
          "title": "Plans",
          "qas": [
            {
              "Q": "What happens if my plan expires?",
              "A": "Your data is not lost. If you want to continue, just buy another plan but ensure to use the same email-address as before to continue. We reserve the right to delete answers and statistics after 24 months of inactivity.",
            },
            {
              "Q": "My plan expires soon, can I extend it?",
              "A": "The time of the new plan will NOT be added to left days in an existing plan. Please wait until the plan expired and join a new plan then.",
            },
            {
              "Q": "Can I see how many days left in current plan?",
              "A": "Sign in into your account and click the orders history link.",
            },
            {
              "Q": "Is there a return policy?",
              "A": "Yes, but please use the free tests to try if you like the way it works. As soon you started a paid plan, you already used the service and had a chance to learn. Please be fair to us and do not request a refund when our service helped you already. In any other case, you can request a refund within 60 days after payment has been processed.",
            },
            {
              "Q": "I bought a plan recently and now it is on sale. Can I get some money back?",
              "A": "No. It is simply very complicated to do this. Sales come on a regular basis. If you don't need it immediately, we recommend to wait for a sale.",
            }


          ]
        },

        {
          "title": "Account",
          "qas": [
            {
              "Q": "How can I cancel my subscription?",
              "A": "Please log in and go to your orders history. If you have a subscription, a link to the payment provider will appear where you can cancel your subscription.",
            },
            {
              "Q": "How can I change my password?",
              "A": "Go to sign-in section, logout if you are logged in and click forgot password. Then you enter your email address and send yourself a verification number. With that number you can come back and set a new password.",
            },
            {
              "Q": "Is my password save?",
              "A": "It is stored in Amazon Cognito, which is very secure and maintained by AWS directly. Your password is not stored in our database.",
            },
            {
              "Q": "How can I change my email address?",
              "A": "At the moment it is not possible by you. Please send us a support request from used email-address.",
            },
            {
              "Q": "How can I delete my account?",
              "A": "Please send us a support request from used email-address.",
            },
            {
              "Q": "I have a coupon for a discount. Where can I use it?",
              "A": "When selecting a plan, you will be forwarded to the payment provider. There you will see a field where you can enter your discount code. Please note that just one code can be used and if we are on sale, maybe there is already a code filled, which in some cases may provide even better conditions.",
            }

          ]
        },


      ],
    }
  },



  mounted () {
    // JQuery
    // FAQ Accordion JS
    $('.accordion').find('.accordion-title').on('click', function(){
      // Adds Active Class
      $(this).toggleClass('active');
      // Expand or Collapse This Panel
      $(this).next().slideToggle('fast');
      // Hide The Other Panels
      $('.accordion-content').not($(this).next()).slideUp('fast');
      // Removes Active Class From Other Titles
      $('.accordion-title').not($(this)).removeClass('active');

    });

  },

  created() {
    window.scrollTo(0,0); // scroll to top
  },

}


</script>